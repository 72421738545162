/* @import url("https://fonts.googleapis.com/css?family=Lato:400,400i,700");

body {
  font-family: Lato, sans-serif;
}

footer {
  width:100vw;
  position:fixed;
  bottom:0px
}
footer div {
  background-color:#4478e3;
  margin: -5px 0px 0px 0px;
  padding:0px;
  color: #fff;
  text-align:center;
}
svg {
  width:100%;
}
.arrow {
  stroke-width: .3px;
  stroke:yellow;
}
.topball {
  animation: ball 1.5s ease-in-out;
  animation-iteration-count:infinite;
  animation-direction: alternate;
  animation-delay: 0.3s;
  cursor:pointer;
}

.wave {
  animation: wave 3s linear;
  animation-iteration-count:infinite;
  fill: #4478e3;
}
.drop {
  fill: transparent;
  animation: drop 5s ease infinite normal;
  stroke: #4478e3;
  stroke-width:0.5;
  opacity:.6; 
  transform: translateY(80%);
}
.drop1 {
  transform-origin: 20px 3px;
}
.drop2 {
  animation-delay: 3s;
  animation-duration:3s;
  transform-origin: 25px 3px;
}
.drop3 {
  animation-delay: -2s;
  animation-duration:3.4s;
  transform-origin: 16px 3px;
}
.gooeff {
  	filter: url(#goo);
}
#wave2 {
  animation-duration:5s;
  animation-direction: reverse;
  opacity: .6
}
#wave3 {
  animation-duration: 7s;
  opacity:.3;
}
@keyframes drop {
  0% {
    transform: translateY(80%); 
    opacity:.6; 
  }
  80% {
    transform: translateY(80%); 
    opacity:.6; 
  }
  90% { 
    transform: translateY(10%) ; 
    opacity:.6; 
  }
  100% { 
    transform: translateY(0%) scale(1.5);  
    stroke-width:0.2;
    opacity:0; 
  }
}
@keyframes wave {
  to {transform: translateX(-100%);}
}
@keyframes ball {
  to {transform: translateY(20%);}
}
 */

 
 .swal-button {
  padding: 7px 19px;
  border-radius: 2px;
  background-color: #002054;
  font-size: 12px;
  border: 1px solid #002054;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3);
}
.swal-button:hover {
  padding: 7px 19px;
  border-radius: 2px;
  background-color: #002054;
  font-size: 12px;
  border: 1px solid #002054;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3);
}



