:root {
  --primaryColor: #002054;
  --secondaryColor: #4fc3f7;
  --white: #fff;
  --black: #000;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Roboto", 'Noto Serif Bengali', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px grey;
  border-radius: 0px;
}

::-webkit-scrollbar-thumb {
  background: var(--primaryColor);
  border-radius: 0px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--primaryColor);
}
