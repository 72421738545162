.SocialContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.Icons{
    color: var(--primaryColor);
    cursor: pointer;
    font-size: 3rem;
    transition: .2s ease-in-out;
}
.Icons:hover{
    color: var(--secondaryColor);
}
